@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Bold.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: bold;
}